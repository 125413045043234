import { AxiosError } from 'axios'

import consoleMessage from 'shared/utils/helpers/consoleMessage'
import isAxiosError from 'shared/utils/helpers/isAxiosError'
import { httpClient } from 'shared/lib'

import { FetchMessagesResult, ChangeMessageStatusPayload } from './types'

export function fetchMessages(): Promise<FetchMessagesResult> {
   let errMessage = ''

   return httpClient
      .get('messages/')
      .then((res) => {
         if (res.data) {
            return res.data
         }
         throw new Error('noData')
      })
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('MESSAGES fetchMessages service', errMessage, 'error')
         throw error
      })
}

export function changeMessageStatus(payload: ChangeMessageStatusPayload) {
   let errMessage = ''

   return httpClient
      .put(`messages/${payload.messageUuid}/isRead/`)
      .then((res) => ({ status: res.status }))
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('MESSAGES changeMessageStatus service', errMessage, 'error')
         throw error
      })
}
