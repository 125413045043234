import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ReportIcon from '@mui/icons-material/Report'
import { styled } from '@mui/material'

export const Grid = styled('div')({
   width: '100%',
   height: '100%',
   display: 'grid',
   gap: 25,
   gridTemplateColumns: 'repeat(8, 1fr)',
   gridTemplateRows: 'repeat(3, 350px)',
})

export const StyledCheck = styled(CheckCircleIcon)({
   fontSize: 14,
   marginBottom: '-2px',
   color: 'green',
})

export const ImportantIcon = styled(ReportIcon)({
   fontSize: 17,
   marginBottom: '-2px',
   color: 'red',
})
