import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles'
import Box from '@mui/material/Box'
import MuiDrawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import CssBaseline from '@mui/material/CssBaseline'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

import routes from 'config/routes'
import useLogin from 'shared/store/useLogin'
import { UserRoles } from 'shared/consts'
import { useProfileQuery } from 'shared/store/useProfile'

const openedMixin = (theme: Theme): CSSObject => ({
   width: theme.sizes.sidebarWidth,
   transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
   }),
   overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
   transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
   }),
   overflowX: 'hidden',
   width: `calc(${theme.spacing(8)})`,
})

const DrawerHeader = styled('div')(({ theme, open }: { theme: Theme; open: boolean }) => ({
   display: 'flex',
   alignItems: 'center',
   marginTop: 64,
   padding: 0,
   marginBottom: -25,
   justifyContent: open ? 'flex-end' : 'center',
   transition: '400ms',
   // necessary for content to be below app bar
   ...theme.mixins.toolbar,
}))

const Drawer = styled(MuiDrawer, {
   shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
   width: theme.sizes.sidebarWidth,
   flexShrink: 0,
   whiteSpace: 'nowrap',
   boxSizing: 'border-box',
   ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
   }),
   ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
   }),
}))

const MENU_GROUPS = [
   {
      name: 'cart',
      label: 'asideLabels.cart',
   },
   // {
   //    name: 'shopping',
   //    label: 'asideLabels.shopping',
   // },
   {
      name: 'settings',
      label: 'asideLabels.settings',
   },
]

interface Props {
   open: boolean
   handleOpen: (openState: boolean) => void
}

export default function Sidebar({ handleOpen, open }: Props) {
   const { t } = useTranslation('AUTHORIZED')
   const navigate = useNavigate()
   const location = useLocation()
   const { data: userData } = useProfileQuery()
   const { user } = useLogin()
   const theme = useTheme()

   // useEffect to rerender function when active location changes
   // eslint-disable-next-line @typescript-eslint/no-empty-function
   React.useEffect(() => {}, [userData?.locationUuid])

   return (
      <Box sx={{ display: 'flex' }}>
         <CssBaseline />

         <Drawer
            // onMouseEnter={() => handleOpen(true)}
            // onMouseLeave={() => handleOpen(false)}
            variant="permanent"
            open={open}
         >
            <DrawerHeader theme={theme} open={open}>
               <IconButton
                  onClick={() => handleOpen(!open)}
                  sx={{
                     cursor: 'pointer',
                     ...(open && { transform: 'rotate(180deg)' }),
                     transition: 'transform 0.5s',
                  }}
               >
                  <ChevronRightIcon />
               </IconButton>
            </DrawerHeader>
            {MENU_GROUPS.map((group) => (
               <React.Fragment key={group.name}>
                  <Typography
                     variant="body1"
                     sx={{
                        color: theme.palette.primary.dark,
                        transition: '300ms',
                        opacity: `${!open ? '0' : '1'}`,
                        ml: 1,
                        fontSize: 15,
                        fontWeight: 'bold',
                     }}
                  >
                     {t(group.label)}
                  </Typography>

                  <Divider />

                  <List>
                     {routes
                        .filter(
                           (route) =>
                              route.group === group.name &&
                              route.inSidebar &&
                              route.authority.some((item) => user.roles.includes(item)) &&
                              !(route.needSub && !user.roles.includes(UserRoles.SUBSCRIPTION))
                        )
                        .map((route) => {
                           const isActive = location.pathname.includes(route.path)
                           return (
                              <ListItem
                                 key={route.name}
                                 onClick={() => navigate({ pathname: route.path })}
                                 sx={{
                                    maxHeight: 35,
                                    color: isActive ? theme.palette.primary.dark : null,
                                    borderRight: isActive ? '3px solid ' : null,
                                    borderRightColor: theme.palette.primary.dark,
                                    '&:hover': {
                                       cursor: 'pointer',
                                       color: theme.palette.primary.dark,
                                       backgroundColor: theme.palette.primary.light,
                                    },
                                 }}
                              >
                                 <ListItemIcon
                                    sx={{
                                       marginRight: open ? -3 : 0,
                                       transition: '400ms',
                                    }}
                                 >
                                    {route.icon}
                                 </ListItemIcon>
                                 <ListItemText primary={t(route.name)} />
                              </ListItem>
                           )
                        })}
                  </List>
               </React.Fragment>
            ))}
         </Drawer>
      </Box>
   )
}
