import { useMutation, useQuery, useQueryClient, UseQueryResult } from 'react-query'

import { useNotificationContext } from 'shared/context/Notifications'
import isAxiosError from 'shared/utils/helpers/isAxiosError'

import { AxiosError } from 'axios'
import { deleteNotification, fetchNotifications, readNotification } from './services'
import { FetchNotificationsResult, NotificationActionsPayload } from './types'

export function useNotificationsQuery(page: number, pageSize: number): UseQueryResult<FetchNotificationsResult> {
   return useQuery('notifications', () => fetchNotifications(page, pageSize), {
      refetchOnWindowFocus: 'always',
   })
}

export function usePagingNotifications() {
   const queryClient = useQueryClient()
   const { addNotification } = useNotificationContext()

   return useMutation<
      FetchNotificationsResult,
      AxiosError<ErrorType> | Error,
      { page: number; pageSize: number },
      unknown
   >(({ page, pageSize }) => fetchNotifications(page, pageSize), {
      onSuccess: (data) => {
         queryClient.setQueryData(['notifications'], data)
      },
      onError: (error) => {
         if (error) {
            if (isAxiosError<ErrorType>(error)) {
               addNotification(error.response?.data?.errors[0].code || 'failedSave', 'error')
            } else {
               addNotification('failedSave', 'error')
            }
         }
      },
   })
}
export function useRemoveNotification() {
   const queryClient = useQueryClient()
   const { addNotification } = useNotificationContext()

   return useMutation<Result, AxiosError<ErrorType> | Error, NotificationActionsPayload, unknown>(
      (payload) => deleteNotification(payload),
      {
         onSuccess: (data) => {
            if (data.status === 200) {
               queryClient.invalidateQueries('notifications')
               queryClient.invalidateQueries('userProfile')
               addNotification('successSave', 'success')
            }
         },
         onError: (error) => {
            if (error) {
               if (isAxiosError<ErrorType>(error)) {
                  addNotification(error.response?.data?.errors[0].code || 'failedSave', 'error')
               } else {
                  addNotification('failedSave', 'error')
               }
            }
         },
      }
   )
}

export function useReadNotification() {
   const queryClient = useQueryClient()
   const { addNotification } = useNotificationContext()

   return useMutation<Result, AxiosError<ErrorType> | Error, NotificationActionsPayload, unknown>(
      (payload) => readNotification(payload),
      {
         onSuccess: (data) => {
            if (data.status === 204) {
               queryClient.invalidateQueries('notifications')
               queryClient.invalidateQueries('userProfile')
            }
         },
         onError: (error) => {
            if (error) {
               if (isAxiosError<ErrorType>(error)) {
                  addNotification(error.response?.data?.errors[0].code || 'failedSave', 'error')
               } else {
                  addNotification('failedSave', 'error')
               }
            }
         },
      }
   )
}
