import CircularProgress from '@mui/material/CircularProgress/CircularProgress'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/material'
import Grow from '@mui/material/Grow'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import React from 'react'

import ContainedButton from 'shared/components/Buttons/ContainedButton'
import { StatisticsType } from 'shared/store/useStatistics/types'
import { useProfileQuery } from 'shared/store/useProfile'

interface Props {
   savedTime: PropType<StatisticsType, 'savedTime'>
   statsFailed: boolean
}

export default function PointsAndSavedTime({ savedTime, statsFailed }: Props) {
   const { t } = useTranslation('HOMEPAGE')
   const { data } = useProfileQuery()
   const theme = useTheme()

   return (
      <Grow in timeout={1600}>
         <Card sx={{ px: 1, width: '100%', height: '100%' }}>
            {!statsFailed ? (
               <>
                  <Box
                     sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: 1,
                        p: { xs: 3 },
                        minHeight: '50%',
                        borderBottom: `1px solid ${theme.palette.primary.light}`,
                        textAlign: 'center',
                     }}
                  >
                     {data ? (
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                           <div>
                              <span>{t('loyaltyPoints')} </span>
                              <span
                                 style={{
                                    fontSize: 20,
                                    fontWeight: 'bold',
                                    color: theme.palette.primary.light,
                                 }}
                              >
                                 {data.loyaltyPoints.toFixed(0)}
                              </span>
                           </div>
                           <ContainedButton
                              variant="contained"
                              onClick={() => window.open('https://nagrody.motorro.pl/', '_blank')}
                           >
                              {t('viewRewards')}
                           </ContainedButton>
                        </Box>
                     ) : (
                        <CircularProgress />
                     )}
                  </Box>
                  <Box
                     sx={{
                        display: 'flex',
                        flexDirection: { md: 'column', xs: 'row' },
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: 0.5,
                        p: { xs: 3 },
                        minHeight: '50%',
                        textAlign: 'center',
                     }}
                  >
                     <span>{t('youSaved')}</span>
                     <span
                        style={{
                           fontSize: 16,
                           fontWeight: 'bold',
                           color: theme.palette.primary.light,
                           textAlign: 'center',
                        }}
                     >
                        {t('savedTime', {
                           months: savedTime.months,
                           days: savedTime.days,
                           hours: savedTime.hours,
                           minutes: savedTime.minutes,
                        })}
                     </span>
                  </Box>
               </>
            ) : (
               <Typography variant="body2">{t('loadingFailed')}</Typography>
            )}
         </Card>
      </Grow>
   )
}
